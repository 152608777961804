/*==========  Non-Mobile First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .main-slider__slides {
    height: 588px;
  }
  .footer-top__col {
    flex: 0 1 25%;
  }
  .footer-top__col:last-child {
    flex: 0 1 25%;
  }
  .tabs-horizontal__content {
    padding: 0 95px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .tabs-vertical {
    flex-direction: column;
  }
  .tabs-vertical__nav {
    margin: 0 0 20px 0;
    text-align: center;
  }
  .tabs-vertical__nav li {
    display: inline-block;
    margin: 0 5px 10px;
  }
  .tabs-vertical__nav li + li {
    margin: 0 0 10px 5px;
  }
  .tabs-vertical__content {
    text-align: center;
    margin: auto;
  }
  .tabs-vertical__tab img {
    max-width: 100%;
  }
  .tabs-horizontal__content {
    padding: 0 45px;
  }
  .popup {
    padding-left: 30px;
    padding-right: 30px;
  }
  .primary-nav a {
    font-size: 13px;
  }
  .main-slider__slides {
    height: 376px;
  }
  .info-box__title {
    font-size: 22px;
    margin: 0 0 30px 0;
  }
  .info-box__text {
    margin: 0 0 35px 0;
    padding: 0;
  }
  .grid-list li {
    margin-bottom: 30px;
  }
  .grid-list__caption {
    min-height: 0;
    padding-bottom: 45px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .site-header {
    margin: 0 0 10px 0;
  }
  .site-header > .container {
    flex-direction: column;
  }
  .site-header__col {
    text-align: center;
    width: 100%;
  }
  .site-header__col:first-child {
    padding: 0;
  }
  .main-slider {
    margin: 0 0 10px 0;
  }
  .primary-nav {
    display: none;
  }
  .btn-nav-toggle {
    display: block;
  }
  .languages {
    margin: 0;
    padding: 20px 0;
  }
  .languages ul {
    justify-content: center;
  }
  .contacts {
    display: inline-flex;
    flex-direction: column;
  }
  .contacts--extra {
    float: none;
  }
  .contacts__item {
    text-align: left;
  }
  .info-box {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .info-box:after {
    content: '';
    font-size: 0;
    height: 20px;
    display: table;
    clear: both;
  }
  .info-box__fig {
    max-width: none;
    width: calc(50% - 10px);
    position: relative;
    text-align: left;
    float: left;
    margin: 0 0 0 10px;
  }
  .info-box__fig:nth-of-type(1) {
    text-align: right;
    margin: 0 10px 0 0;
  }
  .info-box__img {
    max-height: 283px;
    position: static;
    transform: none;
  }
  .info-box__desc {
    margin: 0 0 20px 0;
    padding: 30px 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .footer-title {
    margin-bottom: 20px;
  }
  .footer-top {
    padding: 30px 0 0 0;
    margin: 0 0 30px 0;
    flex-wrap: wrap;
  }
  .footer-top__col {
    width: 50%;
    flex: 0 1 50%;
    margin: 0 0 30px 0;
  }
  .btn-primary--fright {
    float: none;
  }
  .footer-bottom {
    padding: 0 0 30px 0;
    flex-wrap: wrap;
  }
  .footer-bottom__col {
    text-align: center;
    width: 50%;
    flex: 0 0 50%;
  }
  .footer-bottom__col:nth-child(2) {
    margin: 0 0 20px 0;
    width: 100%;
    flex: 0 0 auto;
    order: -1;
  }
  .copyright {
    flex-direction: column;
    text-align: center;
  }
  .grid-list li {
    width: calc((100% - 30px * 1) / 2);
  }
  .grid-list li:nth-child(3n+3) {
    margin-right: 30px;
  }
  .grid-list li:nth-child(2n+2) {
    margin-right: 0;
  }
  .grid-list__caption {
    padding: 20px;
  }
  .gallery {
    margin: 0 0 20px 0;
  }
  .gallery__thumbnails li {
    flex-basis: 120px;
    height: 88px;
  }
  .tabs-horizontal__nav {
    padding: 5px 0 0 0;
  }
  .tabs-horizontal__nav ul {
    flex-wrap: wrap;
  }
  .tabs-horizontal__nav li {
    margin: 0 2.5px 5px;
  }
  .tabs-horizontal__nav a {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .tabs-horizontal__content {
    padding: 0 25px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .main-slider__slides {
    height: 156px;
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-top__col {
    width: auto;
    flex: 0 0 auto;
    margin: 0 0 30px 0;
  }
  .footer-top__col:last-child {
    width: auto;
    flex: 0 0 auto;
  }
  .footer-bottom {
    flex-direction: column;
  }
  .footer-bottom__col {
    width: auto;
    flex: 0 0 auto;
  }
  .contacts--extra {
    margin-bottom: 20px;
  }
  .grid-list li {
    width: 100%;
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
  }
  .grid-list li:nth-child(3n+3) {
    margin-right: auto;
  }
  .grid-list li:nth-child(2n+2) {
    margin-right: auto;
  }
  .gallery__thumbnails {
    display: none;
  }
  .tabs-horizontal__content {
    padding: 0;
  }
  .btn-wrapper .title {
    width: calc(100% - 150px);
  }
  .btn-primary {
    width: 150px;
  }
}

/* Custom, iPhone Retina */
