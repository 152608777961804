/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .main-slider {
    &__slides {
      height: 588px;
    }
  }
  .footer-top {
    &__col {
      flex: 0 1 25%;
      &:last-child {
        flex: 0 1 25%;
      }
    }
  }
  .tabs-horizontal {
    &__content {
      padding: 0 95px;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .tabs {
    &-vertical {
      flex-direction: column;
      &__nav {
        margin: 0 0 20px 0;
        text-align: center;
        li {
          display: inline-block;
          margin: 0 5px 10px;
          & + li {
            margin: 0 0 10px 5px;
          }
        }
      }
      &__content {
        text-align: center;
        margin: auto;
      }
      &__tab {
        img {
          max-width: 100%;
        }
      }
    }
    &-horizontal {
      &__content {
        padding: 0 45px;
      }
    }
  }
  .popup {
    padding-left: 30px;
    padding-right: 30px;
  }
  .primary-nav {
    a {
      font-size: 13px;
    }
  }
  .main-slider {
    &__slides {
      height: 376px;
    }
  }
  .info-box {
    &__title {
      font-size: 22px;
      margin: 0 0 30px 0;
    }
    &__text {
      margin: 0 0 35px 0;
      padding: 0;
    }
  }
  .grid-list {
    li {
      margin-bottom: 30px;
    }
    &__caption {
      min-height: 0;
      padding-bottom: 45px;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .site-header {
    margin: 0 0 10px 0;
    > .container {
      flex-direction: column;
    }
    &__col {
      text-align: center;
      width: 100%;
      &:first-child {
        padding: 0;
      }
    }
  }
  .main-slider {
    margin: 0 0 10px 0;
  }
  .primary-nav {
    display: none;
  }
  .btn-nav-toggle {
    display: block;
  }
  .languages {
    margin: 0;
    padding: 20px 0;
    ul {
      justify-content: center;
    }
  }
  .contacts {
    display: inline-flex;
    flex-direction: column;
    &--extra {
      float: none;
    }
    &__item {
      text-align: left;
    }
  }
  .info-box {
    width: 100%;
    text-align: center;
    margin: 0;
    &:after {
      content: '';
      font-size: 0;
      height: 20px;
      display: table;
      clear: both;
    }
    &__fig {
      max-width: none;
      width: calc(50% - 10px);
      position: relative;
      text-align: left;
      float: left;
      margin: 0 0 0 10px;
      &:nth-of-type(1) {
        text-align: right;
        margin: 0 10px 0 0;
      }
    }
    &__img {
      max-height: 283px;
      position: static;
      transform: none;
    }
    &__desc {
      margin: 0 0 20px 0;
      padding: 30px 20px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .footer-title {
    margin-bottom: 20px;
  }
  .footer-top {
    padding: 30px 0 0 0;
    margin: 0 0 30px 0;
    flex-wrap: wrap;
    &__col {
      width: 50%;
      flex: 0 1 50%;
      margin: 0 0 30px 0;
    }
  }
  .btn-primary {
    &--fright {
      float: none;
    }
  }
  .footer-bottom {
    padding: 0 0 30px 0;
    flex-wrap: wrap;
    &__col {
      text-align: center;
      width: 50%;
      flex: 0 0 50%;
      &:nth-child(2) {
        margin: 0 0 20px 0;
        width: 100%;
        flex: 0 0 auto;
        order: -1;
      }
    }
  }
  .copyright {
    flex-direction: column;
    text-align: center;
  }
  .grid-list {
    li {
      width: calc((100% - 30px * 1) / 2);
      &:nth-child(3n+3) {
        margin-right: 30px;
      }
      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
    &__caption {
      padding: 20px;
    }
  }
  .gallery {
    margin: 0 0 20px 0;
    &__thumbnails {
      li {
        flex-basis: 120px;
        height: 88px;
      }
    }
  }
  .tabs {
    &-horizontal {
      &__nav {
        padding: 5px 0 0 0;
        ul {
          flex-wrap: wrap;
        }
        li {
          margin: 0 2.5px 5px;
        }
        a {
          font-size: 14px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &__content {
        padding: 0 25px;
      }
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .main-slider {
    &__slides {
      height: 156px;
    }
  }
  .footer-top {
    flex-direction: column;
    &__col {
      width: auto;
      flex: 0 0 auto;
      margin: 0 0 30px 0;
      &:last-child {
        width: auto;
        flex: 0 0 auto;
      }
    }
  }
  .footer-bottom {
    flex-direction: column;
    &__col {
      width: auto;
      flex: 0 0 auto;
    }
  }
  .contacts {
    &--extra {
      margin-bottom: 20px;
    }
  }
  .grid-list {
    li {
      width: 100%;
      max-width: 410px;
      margin-left: auto;
      margin-right: auto;
      &:nth-child(3n+3) {
        margin-right: auto;
      }
      &:nth-child(2n+2) {
        margin-right: auto;
      }
    }
  }
  .gallery {
    &__thumbnails {
      display: none;
    }
  }
  .tabs {
    &-horizontal {
      &__content {
        padding: 0;
      }
    }
  }
  .btn-wrapper {
    .title {
      width: calc(100% - 150px);
    }
  }
  .btn-primary {
    width: 150px;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}